import { StopTypeId } from '../models/dto';

export const MarkerUrls = {
    [StopTypeId.PickedUp]: {
        activeIconUrl: 'assets/img/stops-list/marker_pickup.png',
        iconUrl: 'assets/img/stops-list/marker_pickup_sm.png'
    },
    [StopTypeId.Delivered]: {
        activeIconUrl: 'assets/img/stops-list/marker_delivery.png',
        iconUrl: 'assets/img/stops-list/marker_delivery_sm.png'
    },
    [StopTypeId.DeliveredWithReturn]: {
        activeIconUrl: 'assets/img/stops-list/marker_delivery.png',
        iconUrl: 'assets/img/stops-list/marker_delivery_sm.png'
    },
    [StopTypeId.DeliveredWithPickUp]: {
        activeIconUrl: 'assets/img/stops-list/marker_delivery_combo.png',
        iconUrl: 'assets/img/stops-list/marker_delivery_combo_sm.png'
    },
    [StopTypeId.Driver]: {
        activeIconUrl: 'assets/img/stops-list/marker_tuya_driver.png',
        iconUrl: 'assets/img/stops-list/marker_tuya_driver_sm.png'
    },
    [StopTypeId.Nothing]: {
        activeIconUrl: 'assets/img/stops-list/marker_empty.png',
        iconUrl: 'assets/img/stops-list/marker_empty_sm.png'
    },
    [StopTypeId.DriverNotActive]: {
        activeIconUrl: 'assets/img/stops-list/marker_tuya_driver_not_active.png',
        iconUrl: 'assets/img/stops-list/marker_tuya_driver_not_active_sm.png'
    },
    [StopTypeId.DriverWithPackage]: {
        activeIconUrl: 'assets/img/stops-list/marker_tuya_driver_with_package.png',
        iconUrl: 'assets/img/stops-list/marker_tuya_driver_with_package_sm.png'
    }
};
