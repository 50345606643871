import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { RegisterService } from '../services/register.service';
import {
    REGISTER_FCM,
    REGISTER_GET_PERMISSION_FCM,
    RegisterFormActions,
    SUBMIT_REGISTER_FORM,
    SUBMIT_REGISTER_FORM_SUCCESS,
    VALIDATE_ACCOUNT_FORM,
    VALIDATE_COMPANY_FORM,
} from '../actions/register.actions';
import { IndustrySegmentActions, LOAD_INDUSTRY_SEGMENTS } from '../actions/industry.segments.actions';
import { BusinessEntityService } from '../../core/services/business.entity.service';
import { Router } from '@angular/router';
import { AuthActions } from '../../auth/actions/auth.actions';
import {
    transformShipperRegistrationDto,
    transformValidateAccountResult,
    transformValidateCompanyResult,
    cleanValidateAccountAndProfile
} from '../transformations/validate-result.transformation';
import { AuthUserFull } from '../../auth/model/model';
import { ACCESS_TOKEN_KEY, FIRE_TOKEN, REFRESH_TOKEN_KEY, TOKEN_TYPE } from '../../core/constants/storage.constants';
import { PushNotificationService } from '../../core/services/push-notification.service';
import { isServiceWorkerSupported } from '../../core/utils/feature-detections.utils';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'environments/environment.prod';

import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

// Initialize Firebase
const app = initializeApp(environment.firebase);

(async () => {
  try {
    const messagingSupported = await isSupported();
    if (messagingSupported) {
      const messaging = getMessaging(app);
    } else {
      console.warn("Firebase Messaging is not supported in this browser.");
    }
  } catch (error) {
    console.error("Error initializing Firebase Messaging:", error);
  }
})();

@Injectable()
export class RegisterEpics {
    constructor(private registerService: RegisterService,
        private businessEntityService: BusinessEntityService,
        private segmentActions: IndustrySegmentActions,
        private formActions: RegisterFormActions,
        private authActions: AuthActions,
        private msg: PushNotificationService,
        private router: Router) {
    }

    public createEpic() {
        return combineEpics(
            createValidateAccountFormEpic(this.registerService, this.formActions),
            createValidateCompanyFormEpic(this.registerService, this.formActions),
            createSubmitFormEpic(this.registerService, this.formActions),
            createLoadIndustrySegmentsEpic(this.businessEntityService, this.segmentActions),
            createRedirectSubmitFormEpic(this.router, this.authActions),
            createAuthenticateFCMEpic(this.formActions, this.msg),
            createAuthenticateGetPermissionFCMEpic(this.formActions, this.msg)
        );
    }
}

export function createLoadIndustrySegmentsEpic(businessEntityService: BusinessEntityService, actions: IndustrySegmentActions) {
    return (action$) => action$.pipe(
        ofType(LOAD_INDUSTRY_SEGMENTS),
        switchMap(() => {
            return businessEntityService.getIndustrySegments().pipe(
                map(data => actions.loadSucceeded(data))
                ,catchError(response => of(actions.loadFailed({
                    status: '' + response.status,
                }))));
        })
    );
}


export function createValidateAccountFormEpic(registerService: RegisterService, actions: RegisterFormActions) {
    return (action$) => action$.pipe(
        ofType(VALIDATE_ACCOUNT_FORM),
        switchMap((action: any) => {
            return registerService.validateAccountAndProfile(cleanValidateAccountAndProfile(action.payload)).pipe(
                map(data => actions.validateAccountSucceeded(transformValidateAccountResult(data)))
                ,catchError(response => of((response.status === 400)
                    ? actions.validateAccountSucceeded(transformValidateAccountResult(response.error))
                    : actions.validateAccountFailed({
                        status: '' + response.status,
                    }))));
        })
    );
}

export function createValidateCompanyFormEpic(registerService: RegisterService, actions: RegisterFormActions) {
    return (action$) => action$.pipe(
        ofType(VALIDATE_COMPANY_FORM),
        switchMap((action: any) => {
            const fullRegisterShipperDto = transformShipperRegistrationDto(action.payload);
            return registerService.validateEntityAndRefCodeAsync(fullRegisterShipperDto).pipe(
                map(data => actions.submit(action.payload))
                ,catchError(response => of(response.status === 422
                    ? actions.validateCompanySucceeded(transformValidateCompanyResult(response.error))
                    : actions.validateCompanyFailed({
                        status: '' + response.status,
                    }))));
        })
    );
}

export function createSubmitFormEpic(registerService: RegisterService, actions: RegisterFormActions) {
    return (action$) => action$.pipe(
        ofType(SUBMIT_REGISTER_FORM),
        switchMap((action: any) => {
            const fullRegisterShipperDto = transformShipperRegistrationDto(action.payload);
            return registerService.registerShipper(fullRegisterShipperDto).pipe(
                mergeMap(data => {
                    if (!isServiceWorkerSupported()) {
                        // Straight dispatch submitSucceeded
                        return of(actions.submitSucceeded(data));
                    } else {
                        // dispatch the submitSucceeded + registerGetPermissionFCM
                        /*return Observable.of(actions.submitSucceeded(data)).concat(
                         Observable.of(actions.registerGetPermissionFCM(action.payload)));*/
                        return of(actions.registerGetPermissionFCM(data));
                    }
                })
                ,catchError(response => of(actions.submitFailed({
                    status: '' + response.status,
                }))));
        })
    );
}

export function createAuthenticateFCMEpic(
  actions: RegisterFormActions,
  msg: PushNotificationService
) {
  return (action$, store) =>
    action$.pipe(
      ofType(REGISTER_FCM),
      exhaustMap((action: any) =>
        from(
          (async () => {
            try {
              const messagingSupported = await isSupported();
              if (messagingSupported) {
                const messaging = getMessaging(app);
                const token = await getToken(messaging, {
                  vapidKey: "YOUR_PUBLIC_VAPID_KEY_HERE",
                });
                return { success: true, token };
              } else {
                console.warn("Firebase Messaging is not supported in this browser.");
                return { success: false };
              }
            } catch (error) {
              console.error("FCM Token - ERROR", error);
              return { success: false, error };
            }
          })()
        ).pipe(
          tap((result: { success: boolean; token?: string }) => {
            if (result.success && result.token) {
              if (!environment.production) {
                console.debug("FCM Token - GET");
                console.debug(result.token);
              }
              action.payload.firebaseToken = result.token;
              msg.receiveMessage();
              localStorage.setItem(FIRE_TOKEN, result.token);
            }
          }),
          map(() => actions.submitSucceeded(action.payload)),
          catchError((error) => {
            console.error("FCM Token - ERROR", error);
            return of(actions.submitSucceeded(action.payload));
          })
        )
      )
    );
}

/*export function createAuthenticateFCMEpic(
    actions: RegisterFormActions,
    msg: PushNotificationService
  ) {
    return (action$, store) => action$.pipe(
      ofType(REGISTER_FCM),
      exhaustMap((action: any) => 
        from(getToken(messaging)).pipe(
          tap((data: string) => {
            if (!environment.production) {
              console.debug('FCM Token - GET');
              console.debug(data);
            }
            action.payload.firebaseToken = data;
            msg.receiveMessage();
            localStorage.setItem(FCM_TOKEN_KEY, data);
          }),
          map(() => actions.submitSucceeded(action.payload)),
          catchError((error) => {
            console.error('FCM Token - ERROR', error);
            return of(actions.submitSucceeded(action.payload));
          })
        )
      )
    );
  }*/

export function createAuthenticateGetPermissionFCMEpic(
    actions: RegisterFormActions,
    msg: PushNotificationService
  ) {
    return (action$) => action$.pipe(
      ofType(REGISTER_GET_PERMISSION_FCM),
      exhaustMap((action: any) =>
        from(Notification.requestPermission()).pipe(
          map((permissionStatus: NotificationPermission) => {
            if (permissionStatus === 'granted') {
              return actions.registerFCM(action.payload);
            } else {
              throw new Error('FCM not granted after request');
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(actions.submitFailed({ error }));
          }),
          map(() => actions.submitSucceeded(action.payload))
        )
      ),
      catchError((error) => {
        console.error(error);
        return of(actions.submitFailed({ error }));
      })
    );
  }

export function createRedirectSubmitFormEpic(router: Router, authActions: AuthActions) {
    return action$ => action$.pipe(
        ofType(SUBMIT_REGISTER_FORM_SUCCESS),
        map((action: any) => {
            const result: AuthUserFull = <AuthUserFull>action.payload;
            // @TODO
            router.navigate(['']);
            localStorage.setItem(ACCESS_TOKEN_KEY, result.complexToken.access_token);
            localStorage.setItem(REFRESH_TOKEN_KEY, result.complexToken.refresh_token);
            localStorage.setItem(TOKEN_TYPE, result.complexToken.token_type);
            return authActions.loginSucceeded(result);
        })
    );
}
