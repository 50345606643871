import { ShipperProfileDto } from '../../core/models/dto';

export interface IAuthState {
    loggedIn: boolean;
    currentUser: UserProfile;
    error: any;
    isLoading: boolean;
    passwordResetToken?: string;
    forgotPasswordRequestedEmail?: string | null;
    resetPasswordValidationResult: PasswordValidationVO | null;
    changePasswordValidationResult: PasswordValidationVO | null;
    isChangePasswordModalShow: boolean;
    initRefreshFlag: InitFlagType;
    showSuspendAlert: boolean;
}

export enum InitFlagType {
    Done = 1,
    Reset = 0,
    Init = 2
}

export type UserProfile = {
    token: string,
    shipperProfile: ShipperProfileDto,
    adminProfile: ShipperProfileDto,
    error?: any
};

export type AuthUser = {
    token: string,
};

export type AuthUserFull = {
    complexToken: ComplexToken;
    shipperProfile: ShipperProfileDto;
    adminProfile: ShipperProfileDto;
};

export type ComplexToken = {
    access_token: string;
    refresh_token: string;
    expires_in?: number;
    token_type?: string;
    fire_token?: string;
};

export type Credentials = {
    username: string;
    password: string;
    fire_token?: string;
};

export type PasswordValidationVO = {
    isValid: boolean;
    msgs: string[];
    msgsOld: string[];
};

export type User = {
    name: string;
    rememberMe: boolean;
};
